import { useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import Badge from 'shopper/components/Badge';
import Icon from 'shopper/components/Icon';

import { sendEvent } from 'lib/gtag';
import { notificationQueryKeys } from 'lib/queryKeys';

import { useUser } from 'providers/UserProvider';

import { getUnreadNotificationTypesCount } from 'services/notification';

const NotificationDropdown = dynamic(() => import('./NotificationDropdown'), {
  ssr: false,
});

const NotificationButton = ({ gaEventCategory }) => {
  const notificationsButtonRef = useRef(null);
  const [isNotificationsOpen, setNotificationsOpen] = useState(false);
  const { isLoggedIn } = useUser();

  const { data: notificationCounters, isSuccess } = useQuery({
    queryKey: notificationQueryKeys.counts(),
    queryFn: ({ signal }) => getUnreadNotificationTypesCount({ signal }),
    enabled: isLoggedIn,
    refetchOnWindowFocus: true,
  });

  const shouldRenderBadge =
    !isLoggedIn || (isSuccess && notificationCounters?.countAll > 0);

  const onNotificationButtonClick = () => {
    if (!isLoggedIn) {
      sendEvent({
        action: 'notifications_unlogged_open',
        category: gaEventCategory,
      });
    }

    sendEvent({
      action: 'notification_list',
      category: gaEventCategory,
    });
    setNotificationsOpen((prev) => !prev);
  };

  const onNotificationDropdownClose = () => {
    if (!isLoggedIn) {
      sendEvent({
        action: 'notifications_unlogged_close',
        category: gaEventCategory,
      });
    }

    setNotificationsOpen(false);
  };

  return (
    <div className="relative mx-2 block">
      <button
        ref={notificationsButtonRef}
        aria-label="Abrir notificações"
        className={twJoin('relative size-8', isNotificationsOpen && 'z-50')}
        data-test-selector="open-notification-dropdown"
        onClick={onNotificationButtonClick}
      >
        <Icon
          className="text-neutral-low-300"
          name="notification-filled"
          size="size1"
        />
        {shouldRenderBadge && (
          <Badge
            className="absolute right-[10px] top-[5px] "
            shape="dot"
            type="highlight"
          />
        )}
      </button>
      {isNotificationsOpen && (
        <NotificationDropdown
          isOpen={isNotificationsOpen}
          openerRef={notificationsButtonRef}
          onClose={onNotificationDropdownClose}
        />
      )}
    </div>
  );
};

NotificationButton.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
};

export default NotificationButton;
