import TopbarBottom from './TopbarBottom';
import TopbarMain from './TopbarMain';

import TOPBAR from 'constants/topbar';

const Topbar = () => (
  <div className="relative hidden lg:block">
    <TopbarMain gaEventCategory={TOPBAR.GA_EVENT_CATEGORY} />
    <TopbarBottom gaEventCategory={TOPBAR.GA_EVENT_CATEGORY} />
  </div>
);

export default Topbar;
