import { useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import { topbarQueryKeys } from 'lib/queryKeys';
import { isBrowser } from 'lib/utils';

import { useTopbar } from 'providers/TopbarProvider';

import { getTopbarAd } from 'services/ads';

import TopbarDesktop from './Desktop';
import TopbarMobile from './Mobile';

import TOPBAR from 'constants/topbar';

const TopbarAd = dynamic(() => import('./TopbarAd'));

const Topbar = () => {
  const [hideAdSlot, setHideAdSlot] = useState(false);

  const { isTopbarActive } = useTopbar();

  const { data: topbarAd } = useQuery({
    queryKey: topbarQueryKeys.ads(),
    queryFn: ({ signal }) => getTopbarAd({ signal }),
    enabled: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setHideAdSlot(isBrowser() && !topbarAd);
  }, [isBrowser, topbarAd]);

  if (!isTopbarActive) {
    return null;
  }

  return (
    <>
      <div
        className={twJoin(
          'h-11 w-full content-[""] lg:h-8',
          hideAdSlot ? 'hidden' : 'block'
        )}
      >
        {topbarAd && <TopbarAd gaEventCategory={TOPBAR.GA_EVENT_CATEGORY} />}
      </div>
      <header className="sticky top-0 z-40 w-full transition-all duration-150 ease-in lg:mb-9">
        <TopbarDesktop />
        <TopbarMobile />
      </header>
    </>
  );
};

export default Topbar;
