import { getCookie } from 'cookies-next';

import {
  CURRENT_ACTIVE_AB_EXPERIMENTS,
  getVariantCookieKey,
} from 'lib/experiment';

import { log as logger } from './utils';

const isDebuggerActive = !process.env.NEXT_PUBLIC_GOOGLE_GA4_ID;

const log = (...args) => logger('ga', ...args);

const getExperimentMeta = () => {
  let experimentMeta = null;

  if (CURRENT_ACTIVE_AB_EXPERIMENTS.length > 0) {
    CURRENT_ACTIVE_AB_EXPERIMENTS.forEach(({ id }) => {
      const variant = getCookie(getVariantCookieKey(id));

      if (variant) {
        experimentMeta = experimentMeta
          ? `${experimentMeta},${id}:${variant}`
          : `${id}:${variant}`;
      }
    });
  }

  return experimentMeta;
};

export const sendEvent = ({ action, category, label, value }) => {
  const staticOptions = {
    // Identifier for the Promobit 2.0
    version: 2,
    // Using a non-interaction event for all cases is wrong, but was asked by
    // the product team. It might be fixed in the future as all non-interaction
    // are documented
    non_interaction: true,
  };

  if (isDebuggerActive) {
    log('Event sent with the following payload:\n', {
      action,
      category,
      label,
      value,
      ...staticOptions,
    });
    return;
  }

  // Docs: https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
  const eventMeta = {
    ...staticOptions,
    event_category: category,
    value,
  };

  if (label) {
    eventMeta.event_label = label;
  }

  const experimentMeta = getExperimentMeta();

  if (experimentMeta) {
    eventMeta.experiment_meta = experimentMeta;
  }

  window?.gtag('event', action, eventMeta);
};

export const sendWebVitalsToGoogleAnalytics = ({ name, value, id }) => {
  const currValue = Math.round(name === 'CLS' ? value * 1000 : value);
  const payload = {
    event_category: 'Web Vitals',
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    value: currValue,
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics
    // can compute a total by grouping on this ID (note: requires
    // `eventLabel` to be a dimension in your report)
    event_label: id,
    // Use a non-interaction event to avoid affecting bounce rate
    non_interaction: true,
  };

  if (isDebuggerActive) {
    log(`Web vital metric ${name} sent with the following payload:\n`, payload);
    return;
  }

  if (!isDebuggerActive) {
    return;
  }

  window?.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_GA4_ID);
  window?.gtag('event', name, payload);
};

export const sendUserIdToGoogleAnalytics = (userId) => {
  if (isDebuggerActive) {
    log('Send user id to Google Analytics:\n', {
      user_id: userId,
    });
    return;
  }

  window?.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_GA4_ID, {
    user_id: userId,
  });
  window?.gtag('set', { user_id: userId });
};
