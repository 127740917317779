import { appendQsParams, get } from 'lib/request';

export const getLandingsList = async ({ featured, highlighted, type }, opts) =>
  get(
    appendQsParams('/landings', {
      'is-featured': featured,
      'is-highlighted': highlighted,
      type,
    }),
    opts
  );

getLandingsList.fallback = { landings: [] };

export const getLandingInfo = async ({ landingSlug }) =>
  get(`/landings/${landingSlug}`);

export const getLandingOffersList = ({ after, landingSlug, limit }) =>
  get(appendQsParams(`/landings/${landingSlug}/offers`, { after, limit }));
