import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { twJoin } from 'tailwind-merge';

import Box from 'shopper/components/Box';

import Image from 'components/Image';
import UserAvatar from 'components/UserAvatar';

import useDialog from 'hooks/useDialog';
import useMediaQuery from 'hooks/useMediaQuery';
import useTopScrollPosition from 'hooks/useTopScrollPosition';

import { useGlobalQueries } from 'providers/GlobalQueriesProvider';
import { useUser } from 'providers/UserProvider';

import TopbarSearch from '../TopbarSearch';

import APP from 'constants/app';
import LINKS from 'constants/links';
import TOPBAR from 'constants/topbar';

const TimelinesNavigation = dynamic(() => import('./TimelinesNavigation'));

const TopbarMobileDialog = dynamic(
  () => import('components/TopbarMobileDialog'),
  { ssr: false }
);

const TopbarMobile = () => {
  const router = useRouter();
  const { LoggedInUser } = useUser();
  const { showDialog } = useDialog();
  const { isLg } = useMediaQuery();
  const [isTopScrollPosition] = useTopScrollPosition();

  const { activeOffersListTab } = useGlobalQueries();

  const onAvatarClick = () => {
    showDialog(TopbarMobileDialog, {
      gaEventCategory: TOPBAR.GA_EVENT_CATEGORY,
    });
  };

  const onLogoClick = () => {
    if (router.asPath === LINKS.HOME) {
      window.location.reload();
      return;
    }

    router.push(LINKS.HOME);
  };

  const isTimelineNavigationActive =
    isTopScrollPosition && !!activeOffersListTab && !isLg;

  return (
    <>
      <Box
        className={twJoin(
          'flex-row items-center gap-x-4 rounded-none p-4 lg:hidden',
          isTimelineNavigationActive
            ? 'shadow-[0px_-5px_10px_-5px_rgba(87,87,87,0.2)]'
            : 'shadow-[0px_0px_10px_0px_rgba(87,87,87,0.2)] dark:border-b dark:border-neutral-low-300'
        )}
      >
        <button className="size-8" onClick={onLogoClick}>
          <Image
            alt="Promobit"
            height={40}
            src={`${APP.LOCAL_IMAGE_PATH}/general/logos/promobit-logo-icon.svg`}
            width={40}
            priority
          />
        </button>
        <TopbarSearch />
        <button className="size-8" onClick={onAvatarClick}>
          <UserAvatar
            size="size4"
            userName={LoggedInUser?.userName}
            userPhoto={LoggedInUser?.userPhoto}
          />
        </button>
      </Box>
      {isTimelineNavigationActive && (
        <TimelinesNavigation className="shadow-[0px_10px_10px_-5px_rgba(87,87,87,0.2)]" />
      )}
    </>
  );
};

export default TopbarMobile;
