import Script from 'next/script';

import { parseToBool } from 'lib/string';
import { markup } from 'lib/utils';

const MouseflowScriptTag = () => {
  if (!parseToBool(process.env.NEXT_PUBLIC_MOUSEFLOW_ENABLED)) {
    return null;
  }

  return (
    <Script
      dangerouslySetInnerHTML={markup('window._mfq = window._mfq || [];')}
      id="mouseflow-script-url"
      src={`https://cdn.mouseflow.com/projects/${process.env.NEXT_PUBLIC_MOUSEFLOW_ID}.js`}
      strategy="afterInteractive"
    />
  );
};

export default MouseflowScriptTag;
