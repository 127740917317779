import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import useModal from 'hooks/useModal';

import { sendEvent } from 'lib/gtag';
import placeholder from 'lib/placeholder';
import { getFirstPathFromPathname } from 'lib/request';

import { useUser } from 'providers/UserProvider';

import NotificationButton from './NotificationButton';

import LINKS from 'constants/links';

const SuggestNewCouponButton = dynamic(
  () => import('./SuggestNewCouponButton')
);
const SuggestNewOfferButton = dynamic(() => import('./SuggestNewOfferButton'));
const SuggestNewThreadButton = dynamic(
  () => import('./SuggestNewThreadButton')
);
const WishlistModal = dynamic(
  () => import('components/Wishlist/WishlistModal'),
  { ssr: false }
);
const LoggedUserButton = dynamic(() => import('./LoggedUserButton'), {
  ssr: false,
});
const SignUpButton = dynamic(() => import('../SignUpButton'), {
  ssr: false,
});

const TopbarMainRightNav = ({ gaEventCategory }) => {
  const { showModal } = useModal();
  const { isLoggedIn, loadingLoggedInUser } = useUser();
  const { pathname } = useRouter();

  const currInitialPath = getFirstPathFromPathname(pathname);

  const onWishlistButtonClick = () => {
    sendEvent({ action: 'show_wishlist', category: gaEventCategory });
    showModal(WishlistModal);
  };

  const buttonsByPage = useMemo(
    () => ({
      [LINKS.COUPONS]: <SuggestNewCouponButton />,
      [LINKS.FORUM]: <SuggestNewThreadButton />,
      default: <SuggestNewOfferButton />,
    }),
    []
  );

  return (
    <div className="flex justify-end">
      <Button
        className="mr-6 w-[177px]"
        data-test-selector="open-wishlist"
        iconLeft={<Icon name="heart-filled" />}
        mobileIcon={<Icon name="heart-filled" />}
        size="size3"
        type="secondary-stroke"
        onClick={onWishlistButtonClick}
      >
        {placeholder('actions.openWishlist')}
      </Button>
      {buttonsByPage[currInitialPath] ?? buttonsByPage.default}
      <NotificationButton gaEventCategory={gaEventCategory} />
      <div>
        {!loadingLoggedInUser &&
          (isLoggedIn ? (
            <LoggedUserButton gaEventCategory={gaEventCategory} />
          ) : (
            <SignUpButton gaEventCategory={gaEventCategory} />
          ))}
      </div>
    </div>
  );
};

TopbarMainRightNav.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
};

export default TopbarMainRightNav;
