/**
 * TODO: create a way to identify paginated
 * lists by key. Paginated list area created using `useInfiniteQuery` and they
 * have a different data structure compared to queries created via `useQuery`.
 */

export const aliasQueryKeys = {
  key: 'alias',
  listByAliasIds: ({ aliasIds }) => [aliasQueryKeys.key, { aliasIds }],
};

export const articleQueryKeys = {
  key: 'article',
  list: ({ articlesFilter, slug } = { articlesFilter: null, slug: null }) => [
    articleQueryKeys.key,
    'list',
    { articlesFilter, slug },
  ],
};

export const commentQueryKeys = {
  key: 'comment',
  list: ({ commentsSource, commentsPage }) => [
    commentQueryKeys.key,
    'list',
    { commentsSource, commentsPage },
  ],
  offer: {
    key: ({ offerId, sortType }) => [
      commentQueryKeys.key,
      'offer',
      { offerId, sortType },
    ],
    count: ({ offerId }) => [
      ...commentQueryKeys.offer.key({ offerId }),
      'count',
    ],
    isSubscribedForNotifications: ({ offerId }) => [
      ...commentQueryKeys.offer.key({ offerId }),
      'isSubscribedForNotifications',
    ],
    list: ({ offerId, sortType }) =>
      commentQueryKeys.offer.key({ offerId, sortType }),
    mentions: ({ id }) => [
      ...commentQueryKeys.offer.key({ offerId: id }),
      'mentions',
    ],
    ratingsList: ({ offerId }) => [
      ...commentQueryKeys.offer.key({ offerId }),
      'ratingsList',
    ],
  },
  thread: {
    key: ({ threadId, sortType }) => [
      commentQueryKeys.key,
      'thread',
      { threadId, sortType },
    ],
    count: ({ threadId }) => [
      ...commentQueryKeys.thread.key({ threadId }),
      'count',
    ],
    list: ({ threadId, sortType }) =>
      commentQueryKeys.thread.key({ threadId, sortType }),
    mentions: ({ id }) => [
      ...commentQueryKeys.thread.key({ threadId: id }),
      'mentions',
    ],
    ratingsList: ({ threadId }) => [
      ...commentQueryKeys.thread.key({ threadId }),
      'ratingsList',
    ],
  },
  article: {
    key: ({ articleId, sortType }) => [
      commentQueryKeys.key,
      'article',
      { articleId, sortType },
    ],
    count: ({ articleId }) => [
      ...commentQueryKeys.article.key({ articleId }),
      'count',
    ],
    list: ({ articleId, sortType }) => [
      ...commentQueryKeys.article.key({ articleId, sortType }),
    ],
    mentions: ({ id }) => [
      ...commentQueryKeys.article.key({ articleId: id }),
      'mentions',
    ],
    ratingsList: ({ articleId }) => [
      ...commentQueryKeys.article.key({ articleId }),
      'ratingsList',
    ],
  },
};

export const consentQueryKeys = {
  key: 'consent',
  list: () => [consentQueryKeys.key, 'list'],
};

export const conversationQueryKeys = {
  key: 'conversation',
  list: ({ userId }) => [conversationQueryKeys.key, 'list', { userId }],
};

export const couponQueryKeys = {
  key: 'coupon',
  coupon: ({ couponId }) => [couponQueryKeys.key, couponId],
  list: ({ sortType, sidebar }) => [
    couponQueryKeys.key,
    'list',
    { sortType, sidebar },
  ],
  listByStore: ({ storeName, isFinished = false }) => [
    couponQueryKeys.key,
    'store',
    { isFinished, storeName },
  ],
};

export const landingQueryKeys = {
  key: 'landing',
  featured: () => [landingQueryKeys.key, 'featured'],
  highlighted: () => [landingQueryKeys.key, 'highlighted'],
  list: ({ isHighlighted, type }) => [
    landingQueryKeys.key,
    'list',
    { isHighlighted, type },
  ],
};

export const messageQueryKeys = {
  key: 'message',
  list: ({ conversationId, userId }) => [
    messageQueryKeys.key,
    'list',
    { conversationId, userId },
  ],
  feedbackCategories: () => [messageQueryKeys.key, 'feedback', 'categories'],
  ratingCategories: () => [messageQueryKeys.key, 'rating', 'categories'],
};

export const notificationQueryKeys = {
  key: 'notification',
  list: ({ notificationType }) => [
    notificationQueryKeys.key,
    'list',
    { notificationType },
  ],
  counts: () => [notificationQueryKeys.key, 'counts'],
};

export const offerQueryKeys = {
  key: 'offer',
  authors: ({ offerId }) => [offerQueryKeys.key, 'authors', { offerId }],
  offer: ({ offerKey }) => [offerQueryKeys.key, { offerKey }],
  categories: (
    { includeOfferSelections } = { includeOfferSelections: false }
  ) => [offerQueryKeys.key, 'categories', { includeOfferSelections }],
  categoriesAutoCompleteList: ({ query }) => [
    offerQueryKeys.key,
    'categories',
    'autoCompleteList',
    { query },
  ],
  likesCount: ({ offerId }) => [offerQueryKeys.key, 'likesCount', { offerId }],
  list: ({ activeTab }) => [offerQueryKeys.key, 'list', { activeTab }],
  listSortValue: () => [offerQueryKeys.key, 'listSortValue'],
  listByCategory: ({ categoryId, offersSort, offersFiltersVenue }) => [
    offerQueryKeys.key,
    'category',
    { categoryId, offersSort, offersFiltersVenue },
  ],
  listBySelection: ({ selectionSlug }) => [
    offerQueryKeys.key,
    'selection',
    { selectionSlug },
  ],
  listBySubcategory: ({
    subcategoryId,
    offersSort,
    offersFiltersStores,
    offersFiltersMin,
    offersFiltersMax,
    offersFiltersVenue,
  }) => [
    offerQueryKeys.key,
    'subcategory',
    {
      subcategoryId,
      offersSort,
      offersFiltersStores,
      offersFiltersMin,
      offersFiltersMax,
      offersFiltersVenue,
    },
  ],
  listByLanding: ({ landingId }) => [
    offerQueryKeys.key,
    'landing',
    { landingId },
  ],
  listByStore: ({ storeId, offersSort }) => [
    offerQueryKeys.key,
    'store',
    { storeId, offersSort },
  ],
  listFilteredBy: ({ offersSort, value } = { offersSort: 'latest' }) => [
    offerQueryKeys.key,
    { offersSort, value },
  ],
  listByFeatured: () => [offerQueryKeys.key, 'featured'],
  viewMode: () => [offerQueryKeys.key, 'viewMode'],
  validation: ({ offerUrl }) => [
    offerQueryKeys.key,
    'validation',
    { offerUrl },
  ],
  relatedByAliasId: ({ aliasId }) => [
    offerQueryKeys.key,
    'related',
    'alias',
    { aliasId },
  ],
};

export const reviewQueryKeys = {
  key: ({ aliasId }) => ['alias-reviews', { aliasId }],
  featured: ({ aliasId }) => [...reviewQueryKeys.key({ aliasId }), 'featured'],
  list: ({ aliasId, sort }) => [
    ...reviewQueryKeys.key({ aliasId }),
    'list',
    { sort },
  ],
  ratingsList: ({ aliasId }) => [
    ...reviewQueryKeys.key({ aliasId }),
    'ratingsList',
  ],
};

export const userPreferencesQueryKeys = {
  key: 'user-preferences',
  webpush: () => [userPreferencesQueryKeys.key, 'webpush'],
  email: () => [userPreferencesQueryKeys.key, 'email'],
};

export const searchQueryKeys = {
  key: 'search',
  list: ({ searchQuery, searchResultsType }) => [
    searchQueryKeys.key,
    'list',
    { searchQuery, searchResultsType },
  ],
  autoCompleteList: ({ query }) => [
    searchQueryKeys.key,
    'autoComplete',
    'list',
    { query },
  ],
  autoCompleteValue: ({ value } = { value: null }) => [
    searchQueryKeys.key,
    'autoComplete',
    'value',
    { value },
  ],
  suggestedCorrectionResult: ({ searchQuerySuggestedCorrection }) => [
    searchQueryKeys.key,
    'suggestedCorrectionResult',
    { searchQuerySuggestedCorrection },
  ],
  trendsList: () => [searchQueryKeys.key, 'trends', 'list'],
  recommendedLists: () => [searchQueryKeys.key, 'recommended', 'list'],
};

export const storeQueryKeys = {
  key: 'store',
  list: ({ featured } = { featured: false }) => [
    storeQueryKeys.key,
    'list',
    { featured },
  ],
  autoCompleteList: ({ query }) => [
    storeQueryKeys.key,
    'autoCompleteList',
    { query },
  ],
};

export const threadQueryKeys = {
  key: 'thread',
  thread: ({ threadKey }) => [{ threadKey }],
  isSubscribedForNotifications: ({ threadId }) => [
    threadQueryKeys.key,
    { threadId },
    'isSubscribedForNotifications',
  ],
  isLiked: ({ threadId }) => [threadQueryKeys.key, { threadId }, 'isLiked'],
};

/**
 * Topbar shouldn't have query keys but we got abstractions
 * created on the API that doesn't quite fit in any other feature
 * related query keys
 */
export const topbarQueryKeys = {
  key: 'topbar',
  ads: () => [topbarQueryKeys.key, 'ads'],
  counts: () => [topbarQueryKeys.key, 'counts'],
};

export const topContributorRankingQueryKeys = {
  key: 'topContributorRanking',
  byOffers: ({ daysRange }) => [
    topContributorRankingQueryKeys.key,
    'byOffers',
    { daysRange },
  ],
  byComments: ({ commentsSourceName, daysRange }) => [
    topContributorRankingQueryKeys.key,
    'byComments',
    { commentsSourceName, daysRange },
  ],
};

export const userQueryKeys = {
  key: 'user',
  offerLikes: () => [userQueryKeys.key, 'offer', 'likes'],
  offerFilters: () => [userQueryKeys.key, 'offer', 'filters'],
  paginatedFollowsList: ({ userId, type }) => [
    userQueryKeys.key,
    'follows',
    { userId, type },
  ],
  followersList: ({ userId }) => [userQueryKeys.key, 'followers', { userId }],
  interactionsList: ({ userId, activeTabId }) => [
    userQueryKeys.key,
    { userId, activeTabId },
  ],
};

export const wishlistQueryKeys = {
  key: 'wishlist',
  couponKeywords: () => [wishlistQueryKeys.key, 'couponKeywords'],
  /**
   * API returns a whole list of
   * stores, offers categories and subcategories. It should be a list for
   * possible valid keywords using an initial value instead.
   */
  couponValidKeywordsList: () => [
    wishlistQueryKeys.key,
    'couponValidKeywordsList',
  ],
  offerKeywords: () => [wishlistQueryKeys.key, 'offerKeywords'],
  offerKeywordsSuggestions: ({ genericValue }) => [
    wishlistQueryKeys.key,
    'offerKeywordsSuggestions',
    { genericValue },
  ],
};
