import { useCallback } from 'react';

import { removeFromLocalStorage } from 'lib/localStorage';

import useLocalStorageState from './useLocalStorageState';

import LOCAL_STORAGE from 'constants/localStorage';

const OPTIONS = {
  light: 'light',
  dark: 'dark',
};

const resetUserThemePreference = () => {
  removeFromLocalStorage(LOCAL_STORAGE.IS_DARK_MODE_ACTIVE);
};

const useTheme = () => {
  const [isDarkModeActive, setDarkModeActive] = useLocalStorageState(
    LOCAL_STORAGE.IS_DARK_MODE_ACTIVE,
    false
  );

  const currTheme = isDarkModeActive ? OPTIONS.dark : OPTIONS.light;

  // TODO: implement BroadcastChannel to update theme for all open tabs
  const setTheme = useCallback(
    (newTheme) => {
      document.documentElement.classList.remove(currTheme);
      document.documentElement.classList.add(newTheme);

      setDarkModeActive(newTheme === OPTIONS.dark);
    },
    [currTheme]
  );

  const changeThemeToStoredUserPreference = useCallback(() => {
    setTheme(isDarkModeActive ? 'dark' : 'light');
  }, [setTheme]);

  return {
    options: OPTIONS,
    theme: currTheme,
    changeThemeToStoredUserPreference,
    resetUserThemePreference,
    setTheme,
  };
};

export default useTheme;
