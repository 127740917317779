import PropTypes from 'prop-types';

import Avatar from 'shopper/components/Avatar';

import { getUserAvatarUrl, toUserAvatarAltText } from 'lib/image';
import { isGenieUser, isUserAdmin } from 'lib/user';

import SCREENS from 'constants/screens';

// Others sizes have the same size on all resolutions, these won't need
// to use responsive images.
const isResponsiveImageNeeded = (size) => size === 'size1' || size === 'size2';

const sizePropToImageSizeMap = {
  size1: {
    sm: 74,
    lg: 74,
  },
  size2: {
    sm: 64,
    lg: 64,
  },
  size3: {
    sm: 60,
    lg: 60,
  },
  size4: {
    sm: 40,
    lg: 40,
  },
  size5: {
    sm: 32,
    lg: 32,
  },
  size6: {
    sm: 26,
    lg: 26,
  },
};

const UserAvatar = ({
  description = null,
  // This does not have the same behavior as `Image` component as it was
  // done after `Avatar` was already being used in production. It would imply
  // to have all `Avatar`s with lazy loading, which could impact performance
  // negatively
  loading,
  size = 'size4',
  userId = null,
  userLevel = null,
  userName = null,
  userPhoto = null,
  userPhotoSize = null,
  userTypeName = null,
  ...rest
}) => {
  const useResponsiveImage = isResponsiveImageNeeded(size) && !userPhotoSize;

  return (
    <Avatar
      admin={isUserAdmin(userTypeName)}
      description={description || toUserAvatarAltText(userName)}
      level={userId && isGenieUser(userId) ? undefined : userLevel}
      loading={loading}
      size={size}
      sizes={
        useResponsiveImage
          ? `(max-width: ${SCREENS.md}) ${sizePropToImageSizeMap[size].sm}px, ${sizePropToImageSizeMap[size].lg}px`
          : null
      }
      src={getUserAvatarUrl(
        userPhoto,
        userPhotoSize || sizePropToImageSizeMap[size].sm
      )}
      srcSet={
        useResponsiveImage
          ? `${getUserAvatarUrl(userPhoto, sizePropToImageSizeMap[size].sm)} ${
              sizePropToImageSizeMap[size].sm
            }w, ${getUserAvatarUrl(
              userPhoto,
              sizePropToImageSizeMap[size].lg
            )} ${sizePropToImageSizeMap[size].lg}w`
          : null
      }
      {...rest}
    />
  );
};

UserAvatar.propTypes = {
  description: PropTypes.string,
  loading: PropTypes.oneOf(['eager', 'lazy']),
  size: PropTypes.string,
  userId: PropTypes.number,
  userLevel: PropTypes.number,
  userName: PropTypes.string,
  userPhoto: PropTypes.string,
  userPhotoSize: PropTypes.number,
  userTypeName: PropTypes.string,
};

export default UserAvatar;
