import PropTypes from 'prop-types';
import { twJoin } from 'tailwind-merge';

import useTopScrollPosition from 'hooks/useTopScrollPosition';

import TopbarBottomNav from './TopbarBottomNav';

const TopbarBottom = ({ gaEventCategory }) => {
  const [isTopScrollPosition] = useTopScrollPosition();

  return (
    <div
      className={twJoin(
        'absolute right-0 flex w-full border-b border-neutral-high-200 bg-neutral-high-100 px-7 py-2 shadow-md transition-[bottom] duration-300 ease-in dark:border-neutral-low-300 dark:bg-neutral-low-500',
        isTopScrollPosition ? '-bottom-[50px]' : 'bottom-0'
      )}
    >
      <nav className="mx-auto flex items-center md:w-full 3xl:w-[120rem]">
        <TopbarBottomNav gaEventCategory={gaEventCategory} />
      </nav>
    </div>
  );
};

TopbarBottom.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
};

export default TopbarBottom;
