import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';

import { listen } from 'lib/eventManager';
import { sendEvent } from 'lib/gtag';

export const OPEN_MODAL_EVENT = 'open-modal';

const ModalRoot = () => {
  const router = useRouter();
  const modalRef = useRef(null);
  const [Modal, setModal] = useState(null);

  useEffect(() => {
    const closeModal = () => {
      if (modalRef.current) {
        hideModal();
      }
    };
    const unlisten = listen(
      OPEN_MODAL_EVENT,
      ({ detail }) => {
        setModal(detail);
      },
      { runLostEmits: true }
    );

    router.events.on('routeChangeStart', closeModal);

    return () => {
      unlisten();
      router.events.off('routeChangeStart', closeModal);
    };
  }, []);

  const hideModal = () => setModal(null);

  const onCloseHandler = useCallback(() => {
    hideModal();

    if (Modal.props.onClose) {
      Modal.props.onClose();
    }
  }, [Modal]);

  const onOverlayClick = useCallback((event) => {
    if (event.target === modalRef.current) {
      sendEvent({
        action: 'click_outside_modal',
        category: 'login_modal_v4',
      });
      hideModal();
    }
  }, []);

  const forwardedRef = (node) => {
    if (node) {
      node.showModal();
      node.addEventListener('click', onOverlayClick);
    }

    if (modalRef.current) {
      modalRef.current.removeEventListener('click', onOverlayClick);
    }

    modalRef.current = node;
  };

  if (!Modal) {
    return null;
  }

  return (
    <Modal.component
      {...Modal.props}
      forwardedRef={forwardedRef}
      onClose={onCloseHandler}
    />
  );
};

export default ModalRoot;
