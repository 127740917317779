import router from 'next/router';

import { omit } from './object';

import LANDINGS from 'constants/landings';
import LINKS from 'constants/links';
import NOTIFICATION from 'constants/notification';
import { SEARCH_RESULTS_TYPES } from 'constants/searchResults';

export const toAbsoluteLink = (path) =>
  `https://${process.env.NEXT_PUBLIC_SUBDOMAIN}.promobit.com.br${path}`;

export const toArticleCategoryLink = (slug) => `/blog/category/${slug}/`;

export const toArticleLink = (articleSlug, { scrollToComment = null } = {}) =>
  scrollToComment
    ? `/blog/${articleSlug}/#comment:${scrollToComment}`
    : `/blog/${articleSlug}/`;

export const toBlogCategoryLink = (categorySlug) =>
  `/blog/category/${categorySlug}/`;

export const toBlogTagLink = (tagSlug) => `/blog/tag/${tagSlug}/`;

export const toCategoryLink = (slug) => `/promocoes/${slug}/`;

export const toCleanedSearchResultsPage = (resultTypeParam, searchQuery) => {
  const query = {
    ...omit(router.query, ['type', 'sort', 'p', 'a', 'alias']),
    q: searchQuery,
  };

  if (resultTypeParam !== SEARCH_RESULTS_TYPES.GENERAL.PARAM) {
    query.type = resultTypeParam;
  }

  router.push({ pathname: LINKS.SEARCH, query });
};

export const toCouponStoreWithCouponIdLink = (couponId, couponSlug) =>
  couponId
    ? `/cupons/loja/${couponSlug}/#cupom:${couponId}`
    : `/cupons/loja/${couponSlug}/`;

export const toDiscountLandingLink = (offerSlug, aliasId) =>
  `/landing/desconto/?s=${offerSlug}&a=${aliasId}`;

export const toForumLink = ({ categorySlug = null, page = null }) => {
  const pageSlug = page && page !== 1 ? `/pagina/${page}/` : null;
  const basePath = '/forum';
  const basePathWithOptionalCategorySlug = categorySlug
    ? `${basePath}/${categorySlug}`
    : basePath;

  return pageSlug
    ? `${basePathWithOptionalCategorySlug}${pageSlug}`
    : `${basePathWithOptionalCategorySlug}/`;
};

// Make sure to use `string/isUrlValidForInternalUsage` before using this
// as it can leads to error otherwise. `isUrlValidForInternalUsage` is not
// encapsulated because we would need to return a component on this case
export const toInternalLink = (externalUrl = '') =>
  externalUrl.replace(/https:\/\/www\.promobit\.com\.br/, '');

export const toLandingPageLink = (landingSlug, landingType) => {
  if (landingSlug === 'black-friday') {
    return '/black-friday/';
  }

  switch (landingType) {
    case LANDINGS.TYPES.SEASONAL:
      return `/promocoes-do-ano/${landingSlug}/`;
    case LANDINGS.TYPES.INSTITUTIONAL:
      return `/institucional/${landingSlug}/`;
    case LANDINGS.TYPES.BLACK_FRIDAY:
      return `/black-friday/${landingSlug}/`;
    default:
      return null;
  }
};

export const toLastCommentsLink = (slug = null, page = null) => {
  const pagination = page ? `pagina/${page}/` : null;
  const basePath = '/ultimos-comentarios/';
  const basePathWithOptionalSlug = slug ? `${basePath}${slug}/` : basePath;

  return pagination
    ? `${basePathWithOptionalSlug}${pagination}`
    : basePathWithOptionalSlug;
};

export const toMessagesLink = (messageId) =>
  `https://${process.env.NEXT_PUBLIC_SUBDOMAIN}.promobit.com.br/Central/mensagens/?conversation=${messageId}`;

export const toNotificationLink = (
  slug,
  origin,
  { couponIds, commentId } = { commentId: null, couponIds: null }
) => {
  const linkOptionToScrollToComment = commentId
    ? { scrollToComment: commentId }
    : { scrollToComment: null };

  switch (origin) {
    case NOTIFICATION.ORIGINS.OFFER:
      return toOfferLink(slug, linkOptionToScrollToComment);
    case NOTIFICATION.ORIGINS.THREAD:
      return toThreadLink(slug, linkOptionToScrollToComment);
    case NOTIFICATION.ORIGINS.ARTICLE:
      return toArticleLink(slug, linkOptionToScrollToComment);
    case NOTIFICATION.ORIGINS.COUPON:
      return toCouponStoreWithCouponIdLink(couponIds, slug);
    default:
      return null;
  }
};

export const toOfferLink = (slug, { scrollToComment = null } = {}) =>
  scrollToComment
    ? `/oferta/${slug}/#comment:${scrollToComment}`
    : `/oferta/${slug}/`;

export const toOfferRedirectLink = (offerId, qs = '') =>
  `https://${
    process.env.NEXT_PUBLIC_SUBDOMAIN
  }.promobit.com.br/Redirect/to/${offerId}${qs ? '?' : ''}${qs}`;

export const toUrlWithoutHashMark = (url) => url.split(/#\w+/)[0];

export const toUserProfileLink = (userName) => `/usuario/@${userName}/`;

export const toPromobitCouponsWithCouponIdLink = (couponId) =>
  `/cupons/promobit/#cupom:${couponId}`;

export const toRedirectCouponStoreLink = (couponId) =>
  `https://${process.env.NEXT_PUBLIC_SUBDOMAIN}.promobit.com.br/Redirect/cupom/${couponId}`;

export const toSearchResultsLink = (
  searchQuery,
  { type, sort, page } = { type: null, sort: null, page: null }
) => {
  const options = { type, sort, page };
  const basePathWithQuery = `/buscar/?q=${encodeURIComponent(searchQuery)}`;
  const optionsKeys = ['type', 'sort', 'page'];
  const linkStr = Object.values(options)
    .reduce(
      (acc, option, index) =>
        option ? `${acc}&${optionsKeys[index]}=${option}` : acc,
      [basePathWithQuery]
    )
    .toString();

  return linkStr;
};

export const toSearchResultsWithCleanedLink = (options = {}) => {
  const optionsQs = [];

  Object.keys(options).forEach((optionKey) => {
    if (options[optionKey]) {
      optionsQs.push(`${optionKey}=${options[optionKey]}`);
    }
  });

  const concatenatedOptionsQs = optionsQs.join('&');

  return `${LINKS.SEARCH}?${concatenatedOptionsQs}`;
};

export const toSearchResultsWithPaginationPage = (page) =>
  router.push({
    pathname: LINKS.SEARCH,
    query: { ...router.query, p: page },
  });

export const toSearchResultsWithSortPage = (sortValue) =>
  router.push({
    pathname: LINKS.SEARCH,
    // Resets pagination on sort change
    query: { ...omit(router.query, ['p']), sort: sortValue },
  });

export const toSubcategoryLink = (slug) => `/promocoes/${slug}/s/`;

export const toStoreCouponsLink = (slug) =>
  slug ? `/cupons/loja/${slug}/` : '/cupons/';

export const toStoreOffersLink = (slug) =>
  slug ? `/promocoes/loja/${slug}/` : '/lojas/';

export const toThreadLink = (slug, { scrollToComment = null } = {}) =>
  scrollToComment
    ? `/forum/${slug}/#comment:${scrollToComment}`
    : `/forum/${slug}/`;

export const toThreadCategoryLink = (categorySlug) =>
  categorySlug ? `/forum/${categorySlug}/` : '/forum/';

export const toValidInternalLink = (href) => {
  const hrefWithoutOrigin = href.replace(/^https?:\/\//, '');
  const findInvalidUrlInString = hrefWithoutOrigin.match(
    /(https?:\/\/.*?)($|\s)/gi
  );

  if (hrefWithoutOrigin.includes(findInvalidUrlInString)) {
    return hrefWithoutOrigin.replace(findInvalidUrlInString.toString(), '');
  }

  return hrefWithoutOrigin.toLowerCase();
};

export const slugify = (url) => {
  const decodedUrl = decodeURIComponent(url);

  const normalizeUrl = decodedUrl
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/--+/g, '-')
    .replace(/_/g, '-');

  return normalizeUrl;
};
