import { useCallback } from 'react';

import { OPEN_SCROLL_EVENT } from 'components/ScrollControlRoot';

import { dispatch } from 'lib/eventManager';

const useScrollControl = () => {
  const showScrollable = useCallback(
    (Component, props = {}, { priority = 1, persist = false } = {}) => {
      dispatch(OPEN_SCROLL_EVENT, {
        Component,
        props,
        config: { priority, persist },
      });
    },
    []
  );

  return { showScrollable };
};

export default useScrollControl;
