import UniversalSchemas from 'components/Seo/UniversalSchemas';

import GoogleTagManager from './GoogleTagManager';
import MouseflowScriptTag from './MouseflowScriptTag';

const GlobalScripts = () => (
  <>
    <GoogleTagManager />
    <UniversalSchemas />
    <MouseflowScriptTag />
  </>
);

export default GlobalScripts;
