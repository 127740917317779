const SOCIAL_MEDIA = {
  SHARE_UTMS: 'utm_source=promobit&utm_medium=share',
  EMAIL: {
    TITLE: 'Email',
    SHARE_URL: 'mailto:um_amigomit',
  },
  FACEBOOK: {
    APP_ID: process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID,
    SHARE_URL: 'https://www.facebook.com/sharer.php?u=',
    TITLE: 'Facebook',
    URL: 'https://www.facebook.com/promobit',
    USER: '@promobit',
  },
  INSTAGRAM: {
    TITLE: 'Instagram',
    URL: 'https://www.instagram.com/promobitoficial/',
  },
  LINKEDIN: {
    TITLE: 'Linkedin',
    URL: 'https://www.linkedin.com/company/promobit/',
  },
  TELEGRAM: {
    TITLE: 'Telegram',
    URL: 'https://t.me/cupons_desconto',
    USER: '',
    SHARE_URL: 'https://t.me/share/url?url=',
  },
  TWITTER: {
    TITLE: 'Twitter',
    URL: 'https://twitter.com/PromobitOficial',
    USER: '@promobitoficial',
    SHARE_URL: 'https://twitter.com/intent/tweet?url=',
  },
  WHATSAPP: {
    TITLE: 'Whatsapp',
    SHARE_URL: 'https://api.whatsapp.com/send',
  },
};

export default SOCIAL_MEDIA;
