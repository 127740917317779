import dynamic from 'next/dynamic';
import { useEffect } from 'react';

import useLocalStorageState from 'hooks/useLocalStorageState';
import useScrollControl from 'hooks/useScrollControl';

import LOCAL_STORAGE from 'constants/localStorage';

const LGPDAlertDialog = dynamic(() => import('./LGPDAlertDialog'), {
  ssr: false,
});

const DynamicallyLoadedLGPDAlertDialog = () => {
  const { showScrollable } = useScrollControl();
  const [isDialogActive, setDialogActive] = useLocalStorageState(
    LOCAL_STORAGE.IS_SHOWING_LGPD_ALERT,
    true
  );

  useEffect(() => {
    if (!isDialogActive) {
      return;
    }

    showScrollable(
      LGPDAlertDialog,
      {
        onClose: () => {
          setDialogActive(false);
        },
      },
      { priority: 0, persist: true }
    );
  }, []);

  return null;
};

export default DynamicallyLoadedLGPDAlertDialog;
