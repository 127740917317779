const MOBILE_APPS = {
  APP_NAME: 'Promobit',
  ANDROID: {
    APP_URL:
      'https://play.google.com/store/apps/details?id=br.com.promobit.app',
    APP_PACKAGE: 'br.com.promobit.app',
    DESCRIPTION: 'Baixar o aplicativo do Promobit para Android',
  },
  IOS: {
    APP_URL:
      'https://apps.apple.com/br/app/promobit-ofertas-e-descontos/id1112648085',
    DESCRIPTION: 'Baixar o aplicativo do Promobit para iOS',
    STORE_ID: '1112648085',
  },
};

export default MOBILE_APPS;
