import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { EXPERIMENT_ALPHABET, getExpMetaById } from 'lib/experiment';
import { parseToBool } from 'lib/string';

import { useExperiment } from 'providers/ExperimentProvider';

const ALLOW_EXPERIMENT_MANUAL_CONTROL = parseToBool(
  process.env.NEXT_PUBLIC_ALLOW_EXPERIMENT_MANUAL_CONTROL
);
const EXPERIMENT_QS = 'exp';

const useExperimentVariant = (
  expId,
  variants = [],
  { fallback: { beforeExperimentLoadValue, outOfDateValue } } = {
    fallback: { beforeExperimentLoadValue: null, outOfDateValue: undefined },
  }
) => {
  const activeExperiments = useExperiment();
  const { query } = useRouter();

  const activeVariant = useMemo(() => {
    const currentExperimentMeta = getExpMetaById(expId);

    if (!currentExperimentMeta) {
      return [null];
    }

    const isExperimentCurrentlyRunning =
      currentExperimentMeta.endDate > Date.now() &&
      currentExperimentMeta.startDate < Date.now();

    if (
      (!activeExperiments?.[expId] && activeExperiments?.[expId] !== false) ||
      variants.length === 0
    ) {
      /**
       * If the experiment is ran on client, `activeExperiments` will only
       * return the actual active experiments after an `useEffect` is executed.
       * Exceptionally in this render we use the `beforeExperimentLoadValue`
       * value.
       */
      if (isExperimentCurrentlyRunning) {
        return beforeExperimentLoadValue ? [beforeExperimentLoadValue] : [null];
      }

      return outOfDateValue !== undefined ? [outOfDateValue] : [variants[0]];
    }

    if (ALLOW_EXPERIMENT_MANUAL_CONTROL) {
      const experiment = query[EXPERIMENT_QS];

      if (experiment) {
        const [id, variant] = experiment.split(',');
        const variantIndex = EXPERIMENT_ALPHABET.indexOf(variant);

        if (id && variantIndex !== -1) {
          return [variants[variantIndex]];
        }
      }
    }

    return variants.filter((variant, index) => {
      if (index === EXPERIMENT_ALPHABET.indexOf(activeExperiments[expId])) {
        return variant;
      }
    });
  }, [expId, variants, query, activeExperiments]);

  return activeVariant;
};

export default useExperimentVariant;
