import { useEffect } from 'react';

import { listen, mergeListeners } from 'lib/eventManager';
import { sendUserIdToGoogleAnalytics } from 'lib/gtag';
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setLocalStorage,
} from 'lib/localStorage';
import { parseToBool } from 'lib/string';

import { USER_LOGIN_EVENT, USER_LOGOUT_EVENT } from 'providers/UserProvider';

import { postLGPDConsents } from 'services/user';

import LOCAL_STORAGE from 'constants/localStorage';

const {
  IS_POLICY_NUMBER_1_ACCEPTED,
  IS_POLICY_NUMBER_2_ACCEPTED,
  IS_POLICY_NUMBER_3_ACCEPTED,
  IS_POLICY_NUMBER_5_ACCEPTED,
  IS_SHOWING_LGPD_ALERT,
} = LOCAL_STORAGE;

const CONSENTS = [
  {
    consentId: 1,
    storeKey: IS_POLICY_NUMBER_1_ACCEPTED,
  },
  {
    consentId: 2,
    storeKey: IS_POLICY_NUMBER_2_ACCEPTED,
  },
  {
    consentId: 3,
    storeKey: IS_POLICY_NUMBER_3_ACCEPTED,
  },
  {
    consentId: 5,
    storeKey: IS_POLICY_NUMBER_5_ACCEPTED,
  },
];

const getLocalStoredConsents = () =>
  CONSENTS.map(({ consentId, storeKey }) => ({
    consentId,
    consentStatus: Number(getFromLocalStorage(storeKey)),
  }));

const postGiveLGPDConsents = () => {
  const filteredConsents = getLocalStoredConsents().filter(
    ({ consentId }) => consentId > 3
  );

  return postLGPDConsents({ consents: filteredConsents });
};

const resetLGPDConsentsStorage = () => {
  const keys = [
    IS_POLICY_NUMBER_1_ACCEPTED,
    IS_POLICY_NUMBER_2_ACCEPTED,
    IS_POLICY_NUMBER_3_ACCEPTED,
    IS_POLICY_NUMBER_5_ACCEPTED,
    IS_SHOWING_LGPD_ALERT,
  ];

  keys.forEach((key) => removeFromLocalStorage(key));
};

const saveLocalConsents = (consents) =>
  consents.forEach(({ consentId, consentStatus }) => {
    const { storeKey } = CONSENTS.find(
      (consent) => consentId === consent.consentId
    );

    setLocalStorage(storeKey, consentStatus);
  });

const sendUserToAnalytics = (user) => {
  if (parseToBool(getFromLocalStorage(IS_POLICY_NUMBER_1_ACCEPTED))) {
    sendUserIdToGoogleAnalytics(user.userId);
  }
};

const useLGPDConsents = () => {
  useEffect(
    () =>
      mergeListeners(
        listen(USER_LOGOUT_EVENT, resetLGPDConsentsStorage),
        listen(USER_LOGIN_EVENT, postGiveLGPDConsents)
      ),
    []
  );

  return {
    getLocalStoredConsents,
    saveLocalConsents,
    sendUserToAnalytics,
  };
};

export default useLGPDConsents;
