import dynamic from 'next/dynamic';
import { memo, useEffect } from 'react';

import useLocalStorageState from 'hooks/useLocalStorageState';
import useMediaQuery from 'hooks/useMediaQuery';
import useScrollControl from 'hooks/useScrollControl';

import { useUser } from 'providers/UserProvider';

import LOCAL_STORAGE from 'constants/localStorage';

const DownloadAppAlertDialog = dynamic(
  () => import('./DownloadAppAlertDialog'),
  {
    ssr: false,
  }
);

const DynamicallyLoadedDownloadAppAlertDialog = () => {
  const { loadingLoggedInUser, isLoggedIn } = useUser();
  const { isMd } = useMediaQuery();
  const { showScrollable } = useScrollControl();
  const [isDialogActive, setDialogActive] = useLocalStorageState(
    LOCAL_STORAGE.IS_DOWNLOAD_APP_DIALOG_ACTIVE,
    true
  );

  useEffect(() => {
    if (loadingLoggedInUser || isLoggedIn || isMd || !isDialogActive) {
      return;
    }

    showScrollable(
      DownloadAppAlertDialog,
      {
        onClose: () => {
          setDialogActive(false);
        },
      },
      { persist: true }
    );
  }, [loadingLoggedInUser, isMd]);

  return null;
};

export default memo(DynamicallyLoadedDownloadAppAlertDialog);
