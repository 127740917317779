/* eslint-disable jsx-a11y/alt-text */

/* eslint-disable @next/next/no-img-element */

/* eslint-disable react/no-unknown-property */

/**
 * NOTE: `priority` works only in chromium browsers. This is caused because
 * Firefox-based and other browsers ignores manual preloads.
 * Reference: https://www.builder.io/blog/code-prefetching-is-a-lie
 */

/**
 * Browsers that do not support responsive images preload may
 * request the image twice if the image has `priority: true`.
 * They will try to initially preload the `src` image and then,
 * if there is responsive images on `srcSet`, it will request the responsive one
 * See:
 *  - https://caniuse.com/?search=imagesizes
 *  - https://caniuse.com/?search=imagesrcset
 * The only relevant browser that seems to not support these
 * is Safari, which is a good tradeoff.
 * This component aims to have a similar basic API compared to
 * https://nextjs.org/docs/api-reference/next/image so we can transition from it
 * to next/image if needed in the future. The main problem with it now is the
 * styled-system props abstraction which a lot of images depends atm.
 */
import Head from 'next/head';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { twJoin } from 'tailwind-merge';

const getImageComponent = ({
  forwardedRef,
  className,
  grayscale,
  priority,
  loading,
  src,
  srcSet,
  sizes,
  ...rest
}) => {
  const commonProps = {
    className: twJoin(grayscale && 'grayscale', className),
    loading: priority ? null : loading,
    ...rest,
  };

  if (Array.isArray(srcSet) && Array.isArray(sizes)) {
    return (
      <picture ref={forwardedRef}>
        {srcSet.map((item, index) => (
          <source key={item} media={sizes[index]} srcSet={item} />
        ))}
        <img src={src} {...commonProps} />
      </picture>
    );
  }

  return (
    <img
      ref={forwardedRef}
      sizes={sizes}
      src={src}
      srcSet={srcSet}
      {...commonProps}
    />
  );
};

const Image = forwardRef(
  (
    {
      className,
      grayscale = false,
      loading = 'lazy',
      priority = false,
      sizes,
      src,
      srcSet,
      ...rest
    },
    ref
  ) => {
    const ImageComponent = getImageComponent({
      forwardedRef: ref,
      className,
      grayscale,
      priority,
      loading,
      src,
      srcSet,
      sizes,
      ...rest,
    });

    if (priority) {
      return (
        <>
          <Head>
            <link
              // `key` is a unique prop from the Next.js API.
              // Is is used on components inside `Head` to skip
              // possible duplicates, so we don't preload the same image
              // twice if the image is used and preloaded more than once.
              // See: https://nextjs.org/docs/api-reference/next/head
              key={srcSet && sizes ? srcSet : src}
              as="image"
              href={srcSet && sizes ? undefined : src}
              imagesizes={sizes}
              imagesrcset={srcSet}
              rel="preload"
            />
          </Head>
          {ImageComponent}
        </>
      );
    }

    return ImageComponent;
  }
);

Image.displayName = 'Image';

Image.propTypes = {
  grayscale: PropTypes.bool,
  loading: PropTypes.oneOf(['eager', 'lazy']),
  src: PropTypes.string.isRequired,
  /**
   * set-up responsive images using native `srcSet` attribute.
   * When necessary render the image with less DPI for desktop viewports,
   * you can repass to `srcSet` a array of image srcs to manually set when each
   * image should render
   */
  srcSet: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * set-up responsive images using native `sizes` attribute.
   * When necessary render the image with less DPI for desktop viewports,
   * you can repass to `sizes` a array of image srcs to manually set when each
   * image should render
   */
  sizes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  priority: PropTypes.bool,
};

export default Image;
