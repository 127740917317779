import { getFromLocalStorage, setLocalStorage } from 'lib/localStorage';

import {
  postArticleComment,
  postOfferComment,
  postThreadComment,
} from 'services/comment';

const LOCAL_STORAGE_KEY = 'promobit.pending-comments';
/**
 * This is a huge limit for comments and comments characters.
 * No comment will probably be this big or lengthy, but here we
 * make sure pending comments won't ever break the `localStorage` limit.
 */
const PENDING_COMMENT_TEXT_CHAR_LIMIT = 10000;
const PENDING_COMMENTS_LIMIT = 20;
const POST_COMMENT_REQUEST_BY_DOMAIN = {
  article: postArticleComment,
  offer: postOfferComment,
  thread: postThreadComment,
};

const usePendingComments = (domain, domainIdKey) => {
  const createPendingComment = async (comment, domainId) => {
    const stored = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEY) || '[]');
    const { commentText } = comment;

    if (commentText.length < PENDING_COMMENT_TEXT_CHAR_LIMIT) {
      if (stored.length >= PENDING_COMMENTS_LIMIT) {
        stored.pop();
      }

      stored.push({ comment, domain, domainIdKey, domainId });
    } else {
      const { captureMessage } = await import('@sentry/nextjs');

      captureMessage(
        `Comment could not be locally saved as pending, bigger than ${PENDING_COMMENT_TEXT_CHAR_LIMIT}.`
      );
    }

    setLocalStorage(LOCAL_STORAGE_KEY, JSON.stringify(stored));
  };

  const deletePendingComments = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  const deletePendingCommentsFromDomain = (domainId) => {
    const stored = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEY) || '[]');
    const updated = stored.filter(
      (comment) => comment.domain !== domain && comment.domainId !== domainId
    );

    if (updated.length === 0) {
      deletePendingComments();
      return;
    }

    setLocalStorage(LOCAL_STORAGE_KEY, JSON.stringify(updated));
  };

  const getAllPendingComments = () =>
    JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEY) || '[]');

  const getPendingCommentsFromDomain = (domainId) => {
    const stored = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEY) || '[]');

    return stored.filter(
      (comment) => comment.domain === domain && comment.domainId === domainId
    );
  };

  const sendPendingComments = async () => {
    const pendingComments = getAllPendingComments();

    if (pendingComments.length === 0) {
      return;
    }

    try {
      for (const pendingComment of pendingComments) {
        const {
          comment,
          domain: currCommentDomain,
          domainId,
          domainIdKey: currDomainIdKey,
        } = pendingComment;

        await POST_COMMENT_REQUEST_BY_DOMAIN[currCommentDomain]({
          ...comment,
          [currDomainIdKey]: domainId,
        });
      }

      deletePendingComments();
    } catch (e) {
      const { captureException } = await import('@sentry/nextjs');

      captureException(e);
    }
  };

  return {
    createPendingComment,
    deletePendingComments,
    deletePendingCommentsFromDomain,
    getAllPendingComments,
    getPendingCommentsFromDomain,
    sendPendingComments,
  };
};

export default usePendingComments;
